"use client";

import type { Locale } from "@packages/i18n";
import { useLocale, useSetLocale } from "@packages/i18n";
import type { ReactNode } from "react";
import { useEffect } from "react";

import { useRequestMe } from "../data";

export type LocaleProviderProps = {
  children: ReactNode;
};

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const locale = useLocale();
  const { setLocale } = useSetLocale();

  const { query: queryMe } = useRequestMe();

  useEffect(() => {
    if (queryMe.data?.language)
      if (queryMe.data?.language !== locale)
        setLocale({ locale: queryMe.data?.language as Locale });
  }, [locale, queryMe.data]);

  return children;
};
