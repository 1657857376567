"use client";

import { cartSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { useSession } from "../../../session";
import { keyCart, keyPublicCart } from "./keyCart";

export type CartProps = UseRequestQueryProps & {
  priceId?: string;
  promoCode?: string;
};

export const useRequestCart = ({ priceId, promoCode, ...props }: CartProps) => {
  const { status } = useSession();

  return {
    query: useRequestQuery<typeof cartSchema>({
      enabled: typeof priceId === "string" && priceId.length > 0,
      queryFnRequestProps: {
        schema: cartSchema,
        urlBackendPath: `/cart?price_id=${priceId ?? ""}${promoCode ? `&promo_code=${promoCode}` : ""}`,
      },
      queryKey: keyCart({ priceId, promoCode }),
      ...props,
    }),
    checkCart: useRequestMutation({
      mutationFnRequestProps: ({
        promoCode,
      }: Pick<CartProps, "promoCode">) => ({
        enabled: typeof priceId === "string" && priceId.length > 0,
        schema: cartSchema,
        urlBackendPath: `/cart?price_id=${priceId ?? ""}${promoCode ? `&promo_code=${promoCode}` : ""}`,
        method: "GET",
      }),
    }),
    publicQuery: useRequestQuery({
      enabledWithoutSessionToken: true,
      enabled:
        typeof priceId === "string" &&
        priceId.length > 0 &&
        status !== "authenticated",
      queryFnRequestProps: {
        schema: cartSchema,
        urlBackendPath: `/public/cart?price_id=${priceId ?? ""}${promoCode ? `&promo_code=${promoCode}` : ""}`,
        requestType: "other",
      },
      queryKey: keyPublicCart({ priceId, promoCode }),
      ...props,
    }),
  };
};
